<template>
  <div class="notice" style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="search">
      <div>
        <el-button type="primary" @click="addNotice" style="margin-right: 10px"
          >新增公告</el-button
        >
        <el-select
          v-model="search.status"
          placeholder="请选择发布类型"
          @change="handleChangeType"
        >
          <el-option label="已发布" value="0"></el-option>
          <el-option label="已删除" value="1"></el-option>
          <el-option label="待发布" value="2"></el-option>
        </el-select>
      </div>
      <div style="display: flex">
        <el-input
          placeholder="请输入公告标题"
          v-model="search.title"
        ></el-input>

        <el-date-picker
          v-model="search.time"
          align="right"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        <div>
          <el-button type="primary" @click="searchList">搜索</el-button>
          <el-button type="info" @click="reset">重置</el-button>
        </div>
      </div>
    </div>

    <el-dialog
      title="新增系统公告"
      :visible.sync="dialogFormVisible"
      width="60%"
    >
      <el-form :model="form">
        <el-form-item label="标题" :label-width="formLabelWidth">
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="副标题" :label-width="formLabelWidth">
          <el-input v-model="form.title2" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="内容" :label-width="formLabelWidth">
          <div id="richText"></div>
        </el-form-item>
        <el-form-item label="发布时间" :label-width="formLabelWidth">
          <el-date-picker
            v-model="form.date"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label-width="formLabelWidth" style="margin-top: -20px">
          <el-button @click="save(1)">保存</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="saveNotice(1)"
          :disabled="saveType == 0 ? true : false"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <div class="table_con">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题" width="130">
        </el-table-column>
        <el-table-column prop="subtitle" label="副标题" width="130">
        </el-table-column>
        <el-table-column
          prop="content"
          label="公告内容"
          align="center"
          width="110"
        >
          <template slot-scope="scope">
            <el-button size="mini" @click="handleSeeContent(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="noticeStatus" label="公告状态" width="80">
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" width="180">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="180">
        </el-table-column>
        <el-table-column prop="noticeTime" label="发布时间" width="180">
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="150" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-dialog :visible.sync="dialogContent">
        <el-card class="box-card">
          <div id="contentHtml">
            {{ contentFn(listID) }}
          </div>
        </el-card>
      </el-dialog>

      <el-dialog title="编辑系统公告" :visible.sync="dialogEdit" width="60%">
        <el-form :model="form">
          <el-form-item label="标题" :label-width="formLabelWidth">
            <el-input v-model="form.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="副标题" :label-width="formLabelWidth">
            <el-input v-model="form.title2" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="内容" :label-width="formLabelWidth">
            <div id="richText2"></div>
          </el-form-item>
          <el-form-item label="发布时间" :label-width="formLabelWidth">
            <el-date-picker
              v-model="form.date"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth" style="margin-top: -20px">
            <el-button @click="save(2)">保存</el-button>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogEdit = false">取 消</el-button>
          <el-button
            type="primary"
            @click="saveNotice(2)"
            :disabled="saveType2 == 0 ? true : false"
            >确 定</el-button
          >
        </div>
      </el-dialog>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";

import E from "wangeditor"; //引入富文本插件
let editor;
let editorE;

export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  inject: ["reload"],

  data() {
    return {
      BreadcrumbCon: [
        {
          name: "日常管理",
        },
        {
          name: "公告管理",
        },
      ], // 面包屑数据

      search: {
        title: "",
        time: "",
        status: "0",
      },

      dialogFormVisible: false,
      dialogContent: false,
      dialogEdit: false,

      form: {
        title: "",
        title2: "",
        date: "",
      },
      formLabelWidth: "120px",

      saveType: 0,
      saveType2: 0,
      listID: 0,
      dataID: 0,

      tableData: [],

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100, 200],
    };
  },

  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.noticeList,
      method: "POST",
      params: JSON.stringify({
        noticeTitle: "",
        noticeStatus: "",
        createTime: "",
        pageSize: "",
        pageNum: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          res.data.data.list.map((item) => {
            this.changeItem(item);
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      showLoading();
      const opt = {
        url: reqUrl.noticeList,
        method: "POST",
        params: JSON.stringify({
          noticeTitle: this.search.title,
          noticeStatus: this.search.status || "0",
          createTime: this.search.time,
          pageSize: "",
          pageNum: "",
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            res.data.data.list.map((item) => {
              this.changeItem(item);
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    handleChangeType() {
      showLoading();
      const opt = {
        url: reqUrl.noticeList,
        method: "POST",
        params: JSON.stringify({
          noticeTitle: this.search.title,
          noticeStatus: this.search.status || "0",
          createTime: this.search.time,
          pageSize: "",
          pageNum: "",
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            res.data.data.list.map((item) => {
              this.changeItem(item);
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    addNotice() {
      this.dialogFormVisible = true;
      setTimeout(() => {
        editor = new E("#richText");
        editor.config.uploadImgServer = reqUrl.richImageUpload;
        editor.config.uploadFileName = "file";
        editor.create();
        editor.txt.html("");
      }, 100);
    },

    save(index) {
      if (index == 1) {
        this.saveType = 1;

        const opt = {
          url: reqUrl.stringToFileUploadUrl,
          method: "POST",
          params: JSON.stringify({
            fileString: editor.txt.html(),
            title: this.form.title,
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
            } else if (res.data.code == 0) {
              this.$message({
                message: "保存成功",
                type: "success",
              });

              localStorage.setItem("htmlUrl", res.data.data.url);
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
          },
        };
        request(opt);
      }
      if (index == 2) {
        this.saveType2 = 1;

        const opt = {
          url: reqUrl.stringToFileUploadUrl,
          method: "POST",
          params: JSON.stringify({
            fileString: editorE.txt.html(),
            title: this.form.title,
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
            } else if (res.data.code == 0) {
              this.$message({
                message: "保存成功",
                type: "success",
              });

              localStorage.setItem("htmlUrl", res.data.data.url);
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
          },
        };
        request(opt);
      }
    },

    saveNotice(index) {
      if (index == 1) {
        showLoading();
        const opt = {
          url: reqUrl.noticeExamineUrl,
          method: "POST",
          params: JSON.stringify({
            title: this.form.title,
            subtitle: this.form.title2,
            noticeTime: this.form.date,
            noticeUser: localStorage.getItem("userName"),
            content: editor.txt.html(),
            noticeUrl: localStorage.getItem("htmlUrl"),
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            } else if (res.data.code == 0) {
              this.$message({
                message: "成功增加一条新公告",
                type: "success",
              });
              this.dialogFormVisible = false;
              this.form.title = "";
              this.form.title2 = "";
              this.form.date = "";

              this.refresh();
              hideLoading();
            }
            hideLoading();
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            hideLoading();
            console.log(err, "err1");
          },
        };
        request(opt);
      }
      if (index == 2) {
        showLoading();
        let idStr = "";
        this.tableData.map((item) => {
          if (item.id === this.dataID) {
            idStr = item.id;
          }
        });
        const opt = {
          url: reqUrl.updateNoticeExamine,
          method: "POST",
          params: JSON.stringify({
            id: idStr,
            title: this.form.title,
            subtitle: this.form.title2,
            noticeTime: this.form.date,
            noticeUser: localStorage.getItem("userName"),
            content: editorE.txt.html(),
            noticeUrl: localStorage.getItem("htmlUrl"),
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            } else if (res.data.code == 0) {
              this.$message({
                message: "编辑成功",
                type: "success",
              });

              this.dialogEdit = false;
              this.refresh();
              hideLoading();
            }
            hideLoading();
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            hideLoading();
            console.log(err, "err1");
          },
        };
        request(opt);
      }
    },

    handleSeeContent(row) {
      this.listID = row.id;
      this.dialogContent = true;
    },

    handleEdit(row) {
      console.log(row, "row");
      this.dataID = row.id;
      this.dialogEdit = true;
      this.form.title = row.title;
      this.form.title2 = row.subtitle;
      this.form.date = row.noticeTime;
      setTimeout(() => {
        if (editorE) {
          editorE.destroy();
          editorE = null;
        }
        console.log(editorE);
        if (!editorE) {
          editorE = new E("#richText2");
          editorE.config.uploadImgServer = reqUrl.richImageUpload;
          editorE.config.uploadFileName = "file";
          editorE.create();
        }
        console.log(row.content, "row");

        editorE.txt.html(row.content.replace("< ", "<"));
      }, 100);
    },

    handleDelete(row) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          showLoading();
          const opt = {
            url: reqUrl.deleteNoticeExamine,
            method: "POST",
            params: JSON.stringify({ id: row.id }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              } else if (res.data.code == 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                hideLoading();
                this.refresh();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
              console.log(err, "err1");
            },
          };
          request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.noticeList,
        method: "POST",
        params: JSON.stringify({
          noticeTitle: this.search.title,
          noticeStatus: this.search.status || "0",
          createTime: this.search.time,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
            res.data.data.list.map((item) => {
              this.changeItem(item);
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    contentFn(id) {
      let contData = this.tableData.filter((i) => (i.id === id ? i : ""))[0]
        ? this.tableData
            .filter((i) => (i.id === id ? i : ""))[0]
            .content.replace(/\\/g, "")
        : "";
      contData = contData.replace("< ", "<");
      setTimeout(() => {
        const contentHtml = document.querySelector("#contentHtml");
        contentHtml.innerHTML = contData;
      }, 100);
      // contentHtml ? contentHtml.innerHTML("<div>1</div>") : "";
    },

    reset() {
      if (this.search.title || this.search.time) {
        this.refresh();
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可重置项",
        });
      }
    },

    changeItem(item) {
      if (item.noticeStatus == 0) {
        item.noticeStatus = "已发布";
      } else if (item.noticeStatus == 1) {
        item.noticeStatus = "已删除";
      } else if (item.noticeStatus == 2) {
        item.noticeStatus = "待发布";
      }
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  justify-content: space-between;

  .el-input,
  .el-select {
    width: 250px;
    margin-right: 10px;
  }
  .el-select {
    width: 180px;
  }
}
.el-form-item {
  .el-button {
    padding: 10px 10px;
  }
}

::v-deep .el-dialog {
  margin-top: 1vh !important;
}
</style>
